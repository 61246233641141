import * as React from "react";
import { graphql, Link } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
// import Layout from "../../components/layout";
import { slugify } from "../../utils/slugify";
const Page = (props) => {
  const categories = props.data.allStrapiCategory.nodes;
  console.log(categories);
  return (
    <div className="text-white">
      {categories.map((cat, index) => {
        return (
          <Link to={`/categories/${slugify(cat.category)}`}>
            <div key={index} className="">
              {cat.category}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export const query = graphql`
  query CategoriesPage {
    allStrapiCategory {
      nodes {
        category
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Page;
